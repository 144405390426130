import React, { Suspense, Fragment, useState, useEffect } from "react";
import { Layout, Spin, Row, Upload, message, Button, Form, Col } from "antd";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../helpers/appDispatch";
import { appSelector } from "../../helpers/appSelector";
import { clearStates, uploadFile } from "../../store/bulk-payment";

const { Content } = Layout;

const FileUpload = () => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const [excel, setExcel] = useState("");
  const [excelFile, setExcelFile] = useState<File>();
  const { uploadingFile, success, failure, uploadSuccess } = appSelector(
    (state) => state.bulkPayment
  );

  const { user } = appSelector((state) => state.auth);

  const beforeUpload = (file: File): boolean => {
    const isExcel =
      file.type === "text/csv" ||
      file.name.slice(file.name.lastIndexOf(".")) === ".xlsx";

    if (!isExcel) {
      message.error(`${t("general.fileUploadTypeError")}`);
    }
    setExcel(file.name);
    setExcelFile(file);
    return isExcel;
  };
  const uploadButton: React.ReactNode = (
    <div>
      {<PlusOutlined />}
      <div className="ant-upload-text">{t("general.chooseFile")}</div>
    </div>
  );

  let container: React.ReactNode;
  if (uploadingFile) {
    container = (
      <Row className="suspense-container">
        <Spin style={{ marginTop: "20px" }} />
      </Row>
    );
  }

  const onValidateFile = () => {
    const formData = new FormData();
    if (excelFile) {
      formData.append("File", excelFile, excelFile.name);
      formData.append("fileName", excelFile.name);
      formData.append("userId", user ? `${user.userId}` : "");

      dispatch(uploadFile(formData));
    }
  };
  useEffect(() => {
    if (uploadSuccess) {
      dispatch(clearStates());
    }
  }, [uploadSuccess]);
  return (
    <div className="padding-box">
      <Content className="site-layout-background site-box">
        <Suspense
          fallback={
            <Row
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <div style={{ marginTop: "200px" }}>
                <Spin />
              </div>
            </Row>
          }
        >
          <Fragment>
            {/* <div className="card  starter-text stats f-right">1000</div>
            <div className="card  starter-text">1000</div> */}
            <div className="flexy justify-content-end mt-10">
              <Col span={6}>
                {/* <Form.Item name="channel">
                  <Select
                    placeholder={`${t("general.selectProvider")}`}
                    style={{ width: "100%" }}
                    onChange={() => handleChangeEvent()}
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    allowClear
                    showSearch
                  >
                    {providers.length &&
                      providers.map((p) => (
                        <Option value={p.name} key={p.name}>
                          {p.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item> */}
              </Col>
            </div>

            {!success ? (
              <div>
                <div className="upload border">
                  <Upload
                    name="Logo"
                    listType="picture-card"
                    className="seo-uploader"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                  >
                    {!uploadingFile
                      ? excel.length
                        ? excel
                        : uploadButton
                      : failure
                      ? message.error(`${t("general.fileUploadError")}`)
                      : container}
                    {/* {failure
                      ? message.error(`${t("general.fileUploadError")}`)
                      : null} */}
                  </Upload>
                </div>
                <Form.Item className="btn-input login-row-second">
                  <Button
                    type="primary"
                    className="resetButton"
                    onClick={() => onValidateFile()}
                    disabled={!excel || uploadingFile}
                  >
                    {t("general.uploadFile")}
                  </Button>
                </Form.Item>
              </div>
            ) : null}

            {/* {failure ? message.error(`${t("general.fileUploadError")}`) : null} */}
          </Fragment>
        </Suspense>
      </Content>
    </div>
  );
};

export default FileUpload;
