import React, { useState } from 'react'
import { Row, Col, Button, Alert, Input, Form } from 'antd'
import { appSelector } from '../../../helpers/appSelector'
import { FxCurrency, FxRateRq } from '../../../interfaces/FxCurrency'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  updateFxRateRequest
} from '../../../store/settings'
interface Props {
  fxRate: FxCurrency
  onClose: () => void
}

export const EditFxRate: React.FC<Props>= ({ fxRate, onClose }: Props) => {
  const { t } = useTranslation()
  // const { createFxSuccess } = appSelector((state) => state.settings)
  const dispatch = useDispatch()
  const [formData, setFormData] = useState(fxRate)

  const handleSubmit = async () => {
    const payload: FxRateRq = {
      id: formData.id,
      SourceCurrency: formData.SourceCurrency,
      DestinationCurrency: formData.DestinationCurrency,
      Selling: formData.Selling,
      Buying: formData.Buying,
      Median: formData.Median
    }

   await dispatch(updateFxRateRequest(payload))
  }

  return (
    <Row justify="center" align="middle">
      <Col span={24} className="profile-box">
        <h3>{t('general.editCurrency')}</h3>
        <Row>
          <Col span={24}>
            <Form onFinish={handleSubmit}>
              {/* {createFxSuccess && (
                <Alert
                  message={t('general.updateCurrencySuccess')}
                  type="success"
                  closable
                  style={{ marginTop: '20px', width: '100%' }}
                />
              )} */}
              <span style={{ margin: '0 5px' }}></span>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label={t('general.sourceCurrency')}>
                    <Input
                      value={formData?.SourceCurrency}
                      onChange={(e) => setFormData({ ...formData, SourceCurrency: e.target.value })}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={t('general.destinationCurrency')}>
                    <Input
                      value={formData.DestinationCurrency}
                      onChange={(e) =>
                        setFormData({ ...formData, DestinationCurrency: e.target.value })
                      }
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label={t('general.selling')}>
                    <Input
                      value={formData.Selling}
                      onChange={(e) => setFormData({ ...formData, Selling: parseFloat(e.target.value) })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={t('general.buying')}>
                    <Input
                      value={formData.Buying}
                      onChange={(e) => setFormData({ ...formData, Buying: parseFloat(e.target.value) })}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label={t('general.median')}>
                    <Input
                      value={formData.Median}
                      onChange={(e) => setFormData({ ...formData, Median: parseFloat(e.target.value) })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={t('general.createdBy')}>
                    <Input
                      value={formData.CreatedBy}
                      onChange={(e) => setFormData({ ...formData, CreatedBy: e.target.value })}
                      disabled={true}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label={t('general.createdAt')}>
                    <Input
                      value={formData.CreatedWhen}
                      onChange={(e) => setFormData({ ...formData, CreatedWhen: e.target.value })}
                      disabled={true}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={t('general.createdAtUTC')}>
                    <Input
                      value={formData.CreatedWhenUTC}
                      onChange={(e) => setFormData({ ...formData, CreatedWhenUTC: e.target.value })}
                      disabled={true}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Button type="primary" htmlType="submit" disabled={false}>
                {t('general.update')}
              </Button>
              <span style={{ margin: '0 5px' }}></span>
              <Button type="default" onClick={() => onClose()} disabled={false}>
                {t('general.close')}
              </Button>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
export default EditFxRate