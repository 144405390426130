import {
  FilePreview,
  PaymentFile,
  Providers,
} from "../../interfaces/BulkPayment";

export enum BulkPaymentTypes {
  UPLOAD_FILE = "@@bulk-payment/UPLOAD_FILE",
  FILE_PREVIEW = "@@bulk-payment/FILE_PREVIEW",
  FILE_PREVIEW_REQUEST = "@@bulk-payment/FILE_PREVIEW_REQUEST",
  UPLOAD_FILE_SUCCESS = "@@bulk-payment/FILE_UPLOAD_SUCCESS",
  UPLOAD_FILE_FAILED = "@@bulk-payment/FILE_UPLOAD_FAILED",
  GET_PROVIDERS = "@@bulk-payment/GET_PROVIDERS",
  GET_PROVIDERS_SUCCESS = "@@bulk-payment/GET_PROVIDERS_SUCCESS",
  UPLOAD_FILE_FAILURE = "@@bulk-payment/FILE_UPLOAD_FAILURE",
  INCREASE_BULK_PAYMENT_STEP = "@@bulk-payment/INCREASE_BULK_PAYMENT_STEP",
  INCREASE_BULK_PAYMENT_STEP_CUSTOM = "@@payment/INCREASE_BULK_PAYMENT_STEP_CUSTOM",
  DECREASE_BULK_PAYMENT_STEP = "@@payment/DECREASE_BULK_PAYMENT_STEP",
  BULK_PAYMENT_STEP_CUSTOM = "@@bulk-payment/DECREASE_BULK_PAYMENT_STEP_CUSTOM",
  COMPLETE_PAYMENT_REQUEST = "@@bulk-payment/COMPLETE_PAYMENT_REQUEST",
  COMPLETE_PAYMENT_SUCCESS = "@@bulk-payment/COMPLETE_PAYMENT_SUCCESS",
  COMPLETE_PAYMENT_FAILURE = "@@bulk-payment/COMPLETE_PAYMENT_FAILURE",
  CLEAR_STATES = "@@bulk-payment/CLEAR_STATES",
  GET_BULKPAYMENT_FILES_REQUEST = "@@bulk-payment/GET_BULKPAYMENT_FILES_REQUEST",
  GET_BULKPAYMENT_FILES_SUCESS = "@@bulk-payment/GET_BULKPAYMENT_FILES_SUCESS",
  GET_BULKPAYMENT_FILES_FAILURE = "@@bulk-payment/GET_BULKPAYMENT_FILES_FAILURE",
}

export type BulkPaymentState = {
  readonly pages: FilePreview[];
  readonly processing: boolean;
  readonly loading: boolean;
  readonly isSubmitting: boolean;
  readonly error: any;
  readonly success: boolean;
  readonly uploaded: boolean;
  readonly files: PaymentFile[];
  readonly failed: boolean;
  readonly failure: boolean;
  readonly step: number;
  readonly fileId: number;
  readonly filePreviewSuccess: boolean;
  readonly providerName: string;
  readonly providers: Providers[];
  readonly bulkpay: null;
  readonly loadingFiles: boolean;
  readonly uploadingFile: boolean;
  readonly uploadSuccess: boolean;
};
