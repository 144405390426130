import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import { Layout, Row, Col, Card, Button } from "antd";
// import { PageDetails } from "../components/[pageId]/PageDetails";
// import { PageTransactions } from "../components/[pageId]/PageTransactions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../helpers/appDispatch";
import { appSelector } from "../helpers/appSelector";

import { TransactionHistory } from "../interfaces";
import {
  clearStates,
  getFilesRequest,
  uploadedFilePreview,
} from "../store/bulk-payment";

import { PageTransactions } from "../components/[fileId]/PageTransactions";
import { FilePreview } from "../interfaces/BulkPayment";

interface PageProps {}

interface ParamProps {
  fileId: string;
}

const PaymentFile: React.FC<PageProps> = () => {
  const { Content } = Layout;
  const dispatch: AppDispatch = useDispatch();
  const { fileId } = useParams<ParamProps>();
  //   const page = appSelector((state) => state.page);
  const bulkPay = appSelector((state) => state.bulkPayment);
  const { isSubmitting, success, error } = appSelector(
    (state) => state.bulkPayment
  );
  const [loading, setLoading] = useState(false);

  const [payments, setPayments] = useState<FilePreview[]>([]);

  useEffect(() => {
    // dispatch(clearStates());
    dispatch(uploadedFilePreview(parseInt(fileId)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { pages, uploadingFile } = bulkPay;
    setLoading(uploadingFile);
    setPayments(pages);
  }, [bulkPay]);

  return (
    <div className="padding-box">
      <Content className="site-layout-background site-box">
        <div className="margin-top">
          <Card>
            <Row>
              {/* <Col
                span={12}
                style={{ padding: 24 }}
                className="site-layout-background"
              >
                <PageDetails />
              </Col> */}
              <Col span={24} className="bg-gray" style={{ padding: 24 }}>
                <PageTransactions
                  fileId={parseInt(fileId)}
                  isSubmiting={isSubmitting}
                  success={success}
                  loading={loading}
                  transactions={payments}
                  errors={error}
                />
              </Col>
            </Row>
          </Card>
        </div>
      </Content>
    </div>
  );
};

export default withRouter(PaymentFile);
