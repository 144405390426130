import React, { useState } from 'react'
import { Row, Col, Table, Button, Form } from 'antd'

import 'antd-country-phone-input/dist/index.css'
import { appSelector } from '../../../helpers/appSelector'
import moment from 'moment'
import { FxCurrency, FxRateRq } from '../../../interfaces/FxCurrency'
import { EditFxRate } from './EditFX'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../helpers/appDispatch'
import { getFxRateRequest } from '../../../store/settings'
import AddFxRate from './AddFX'

interface Props {
  translate: any,
}

const ManageFX: React.FC<Props> = ({ translate }) => {
  const dispatch: AppDispatch = useDispatch()
  const { fxCurrencies } = appSelector((state) => state.settings)
  const [switchView, setSwitchView] = useState(false)
  const [screen, setScreen] = useState<''| "edit"| "add">('')
  const [fxRate, setFxRate] = useState<FxCurrency | null>(null)

  let tzdiff = (new Date().getTimezoneOffset() / 60) * -1

  const editFx = (row: any): void => {
    setScreen("edit")
    const currency: FxCurrency = {
      id: row.id,
      SourceCurrency: row.sourceCurrency,
      DestinationCurrency: row.destinationCurrency,
      Selling: row.selling,
      Buying: row.buying,
      Median: row.median,
      CreatedBy: row.createdBy,
      CreatedWhen: row.createdWhen,
      CreatedWhenUTC: row.createdWhenUTC
    }
    setFxRate(currency)
    setSwitchView(true)
  }

  const onClose = () => {
    form.resetFields()
    dispatch(getFxRateRequest())
    setSwitchView(false)
  }

  const onAddFxRate = () => {
    setSwitchView(true)
    setScreen("add")
  }

  const [form] = Form.useForm();
  const initialValues: FxRateRq = {
    SourceCurrency: "",
    DestinationCurrency: "",
    Selling: "",
    Buying: "",
    Median: ""
  };

  const columns: any = [
    {
      title: `${translate('general.sourceCurrency')}`,
      dataIndex: 'sourceCurrency',
      key: 'sourceCurrency',
      className: 'column-text',
    },
    {
      title: `${translate('general.destinationCurrency')}`,
      dataIndex: 'destinationCurrency',
      key: 'destinationCurrency',
      className: 'column-text',
    },
    {
      title: `${translate('general.selling')}`,
      dataIndex: 'selling',
      key: 'selling',
      className: 'column-text',
    },
    {
      title: `${translate('general.buying')}`,
      dataIndex: 'buying',
      key: 'buying',
      className: 'column-text',
    },
    {
      title: `${translate('general.median')}`,
      dataIndex: 'median',
      key: 'median',
      className: 'column-text',
    },
    {
      title: `${translate('general.createdBy')}`,
      dataIndex: 'createdBy',
      key: 'createdBy',
      className: 'column-text',
    },
    {
      title: `${translate('general.createdAt')}`,
      dataIndex: 'createdWhen',
      key: 'createdWhen',
      className: 'column-text',
      render: (createdWhen: string) => {
        const d = moment(createdWhen).add(tzdiff, 'hour').format(`MM/DD/YYYY (h:mm a)`)
        return <span>{d}</span>
      },
    },
    {
      title: `${translate('general.actions')}`,
      key: 'row',
      render: (row: any) => {
        return (
          <>
            <Button type="primary" danger onClick={() => editFx(row)}>
              {translate('general.edit')}
            </Button>
            <span style={{ margin: '0 5px' }}></span>
          </>
        )
      },
    },
  ]

  return (
    <Row justify="center" align="middle">
      <Col span={24} className="profile-box">
        <h3>{translate(' ')}</h3>

        <Row>
          <Col span={24}>
            {/* {updateMerchantError && <Alert message={updateMerchantError} type="error" closable />} */}
            <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom:'10px'}}>
              <Button
                type="primary"
                className="reset"
                htmlType="submit"
                size="large"
                onClick={() => onAddFxRate()}
              >
                {translate("general.createFx")}
              </Button>
            </div>
            {!switchView ?
              <Table dataSource={fxCurrencies} columns={columns} />
              :
              switchView && screen === "edit"?
                <EditFxRate fxRate={fxRate!} onClose={() => onClose()} />
                :
                switchView && screen === "add" ?
                  <AddFxRate
                    isSubmitting={false}
                    Form={Form}
                    form={form}
                    errors={false}
                    success={false}
                    values={initialValues}
                    translate={translate}
                    onClickBackButton={()=> onClose()}
                  />
                  : null
            }
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default ManageFX
