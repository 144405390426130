import React from "react";
import { TransactionHistory } from "../../interfaces";
import { Tag, Table, Row, Button } from "antd";
import { timeZones, transactionStatus } from "../../helpers/constants";
import moment from "moment";
import { FilePreview, PaymentStatus } from "../../interfaces/BulkPayment";

interface TransactionsProps {
  transactions: FilePreview[];
}

interface Preview {
  key: number;
  msisdn: string;
  amount: string;
  currency: string;
  statusId: number;
  date: string;
}

export const Transactions: React.FC<TransactionsProps> = ({ transactions }) => {
  const columns: any[] = [
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      align: "center",
    },
    // {
    //   title: 'Reference',
    //   dataIndex: 'customer',
    //   key: 'customer',
    //   align: 'center',
    // },
    {
      title: "Phone Number",
      dataIndex: "msisdn",
      key: "msisdn",
      align: "center",
    },
    {
      title: "Uploaded on",
      dataIndex: "date",
      key: "date",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "statusId",
      key: "statusId",
      align: "center",
      render: (status: number) => {
        let color: string = "geekblue";
        switch (status) {
          case PaymentStatus.APPROVED:
            color = "green";
            break;
          case PaymentStatus.FAILED:
            color = "volcano";
            break;
          case PaymentStatus.PENDINGAPPROVAL:
            color = "geekblue";

            break;
          default:
            color = "geekblue";
            break;
        }
        return (
          <Tag color={color} key={status}>
            {status === 1
              ? "APPROVED"
              : status === 3
              ? "FAILED"
              : "PENDING APPROVAL"}
          </Tag>
        );
      },
    },
    // {
    //   title: "Merchant",
    //   dataIndex: "merchant",
    //   key: "merchant",
    //   align: "left",
    // },
  ];

  let dataSource: Preview[] = [];

  for (let transaction of transactions) {
    dataSource.push({
      key: transaction?.id,
      amount: `${transaction.currency} ${transaction.amount.toFixed(2)}`,

      date: moment(transaction.paymentDate)
        .tz(timeZones.kinshasa)
        .format("MMMM D, YYYY (h:mm a)"),
      statusId: transaction.statusId,
      currency: transaction.currency,
      msisdn: transaction.msisdn,
    });
  }
  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      pagination={{ pageSize: 10 }}
    />
  );
};
