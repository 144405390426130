import React from "react";
import { Table, Row, Col, Spin } from "antd";
import { appSelector } from "../../helpers/appSelector";
import { Transaction } from "../../interfaces/BulkPayment";

interface Props {
  transaction: Transaction[];
  loading: boolean;
  translate: any;
}
interface TableData {
  amount: number;
  msisdn: string;
  providerName: string;
  currency: string;
}

const ProcessFile: React.FC<Props> = ({
  //onClickRow,
  loading,
  translate,
}) => {
  const columns: any[] = [
    {
      title: <span style={{ fontSize: "2rem", color: "#868686" }}>&bull;</span>,
      dataIndex: "status",
      key: "status",
      align: "center",
      className: "column-text",
      render: () => {
        return (
          <span style={{ fontSize: "2rem", color: "#41b883" }}>&bull;</span>
        );
      },
    },
    {
      title: `${translate("general.msisdn")}`,
      dataIndex: "msisdn",
      key: "msisdn",
      align: "center",
      render: (pageName: string, page: TableData) => (
        <span
          style={{ color: "#35b9e6" }}
          //onClick={() => onClickRow(page.msisdn)}
        >
          {pageName}
        </span>
      ),
    },
    {
      title: `${translate("general.provider")}`,
      dataIndex: "providerName",
      key: "providerName",
      align: "center",
    },
    {
      title: `${translate("general.currency")}`,
      dataIndex: "currency",
      key: "currency",
      align: "center",
    },
    {
      title: `${translate("general.amount")}`,
      dataIndex: "amount",
      key: "amount",
      align: "center",
    },
    {
      title: `${translate("general.transactionStatus")}`,
      dataIndex: "transactionStatus",
      key: "transactionStatus",
      align: "center",
    },
  ];
  // const dispatch: AppDispatch = useDispatch()
  const { pages } = appSelector((state) => state.bulkPayment);

  let data: TableData[] = [];
  for (let page of pages) {
    data.push({
      amount: page ? page.amount : 0,
      msisdn: page ? page.msisdn : "",
      providerName: "",
      currency: page ? page.currency : "",
    });
  }

  return (
    <div>
      <Row gutter={20}>
        {pages.length ? (
          <Col span={24}>
            <div className="table-padding">
              <Table
                dataSource={data}
                columns={columns}
                bordered
                pagination={{
                  hideOnSinglePage: true,
                  total: data.length,
                  showTotal: (total, range) => {
                    const tran = translate(`general.pagination`);
                    let t = tran.replace(`%d`, `${range[0]} - ${range[1]}`);
                    let s = t.replace(`%s`, total);
                    return s;
                  },
                }}
                loading={loading}
              />
            </div>
          </Col>
        ) : (
          <Spin style={{ marginTop: "20px", marginLeft: "50rem" }} />
        )}
      </Row>
    </div>
  );
};

export default ProcessFile;
