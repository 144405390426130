import { Reducer } from "redux";
import { BulkPaymentState, BulkPaymentTypes } from "./types";
import { AuthActionTypes } from "../auth";

export const initialState: BulkPaymentState = {
  error: undefined,
  failure: true,
  loading: false,
  pages: [],
  success: false,
  failed: false,
  isSubmitting: false,
  step: 1,
  fileId: 0,
  processing: false,
  filePreviewSuccess: false,
  providerName: "",
  providers: [],
  bulkpay: null,
  uploaded: false,
  files: [],
  loadingFiles: false,
  uploadingFile: false,
  uploadSuccess: false,
};

const reducer: Reducer<BulkPaymentState> = (state = initialState, action) => {
  switch (action.type) {
    case AuthActionTypes.DESTROY_STATES:
      return initialState;
    case BulkPaymentTypes.GET_PROVIDERS_SUCCESS:
      return {
        ...state,
        providers: action.payload,
      };
    case BulkPaymentTypes.UPLOAD_FILE:
      return {
        ...state,
        loading: true,
        uploadingFile: true,
      };
    case BulkPaymentTypes.FILE_PREVIEW_REQUEST:
      return {
        ...state,
        error: {},

        processing: false,
      };
    case BulkPaymentTypes.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        error: {},
        uploaded: true,
        processing: false,
        loading: false,
        fileId: action.payload,
        uploadingFile: false,
        uploadSuccess: true,
      };
    case BulkPaymentTypes.UPLOAD_FILE_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        error: action.payload,
        uploaded: false,
        failure: true,
        loading: false,
        uploadingFile: false,
        uploadSuccess: false,
      };
    case BulkPaymentTypes.UPLOAD_FILE_FAILED:
      return {
        ...state,
        isSubmitting: false,
        error: action.payload,
        uploaded: false,
        failed: true,
        loading: false,
        uploadingFile: false,
        uploadSuccess: false,
      };
    case BulkPaymentTypes.FILE_PREVIEW:
      return {
        ...state,
        pages: action.payload,
        error: {},

        processing: false,
        filePreviewSuccess: true,
      };
    case BulkPaymentTypes.INCREASE_BULK_PAYMENT_STEP:
      return {
        ...state,
        step: state.step + 1,
        processing: true,
        success: false,
      };
    case BulkPaymentTypes.DECREASE_BULK_PAYMENT_STEP:
      return {
        ...state,
        step: state.step - 1,
      };
    case BulkPaymentTypes.COMPLETE_PAYMENT_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        error: {},
        success: false,
        failure: false,
      };
    case BulkPaymentTypes.COMPLETE_PAYMENT_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        bulkpay: action.payload,
        success: true,
        failure: false,
        // uploadSuccess: false,
        error: {},
      };
    case BulkPaymentTypes.COMPLETE_PAYMENT_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        error: action.payload,
        success: false,
        failure: true,
      };
    case BulkPaymentTypes.CLEAR_STATES:
      return {
        ...state,
        isSubmitting: false,
        success: false,
        error: undefined,
        failure: false,
        failed: false,
        loading: false,
        processing: false,
        uploadSuccess: false,
        providers: [],
        pages: [],
        loadingFiles: false,
        files: [],
        uploaded: false,
      };

    case BulkPaymentTypes.GET_BULKPAYMENT_FILES_REQUEST:
      return {
        ...state,
        loadingFiles: true,
      };
    case BulkPaymentTypes.GET_BULKPAYMENT_FILES_SUCESS:
      return {
        ...state,
        loadingFiles: false,
        files: action.payload,
      };
    case BulkPaymentTypes.GET_BULKPAYMENT_FILES_FAILURE:
      return {
        ...state,
        loadingFiles: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export { reducer as bulkPaymentReducer };
