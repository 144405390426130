import React, { Suspense, Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Layout, Spin, Row } from "antd";
import { useTranslation } from "react-i18next";
import DataTable from "../components/bulkPayment/DataTable";
import { appSelector } from "../helpers/appSelector";
// import Wizard from "../components/bulkPayment/Wizard";
import FileUplaod from "../components/bulkPayment/FileUplaod";
import ProcessFile from "../components/bulkPayment/ProcessFile";
import { clearStates } from "../store/bulk-payment";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../helpers/appDispatch";
import { FilePreview } from "../interfaces/BulkPayment";
const { Content } = Layout;

const BulkPayment = () => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const { pages, processing, uploadSuccess, isSubmitting, success } =
    appSelector((state) => state.bulkPayment);

  // const [pageData] = useState(pages);
  useEffect(() => {
    // if () {
    dispatch(clearStates());
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="padding-box">
      <Content className="site-layout-background site-box">
        <Suspense
          fallback={
            <Row
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <div style={{ marginTop: "200px" }}>
                <Spin />
              </div>
            </Row>
          }
        >
          <Fragment>
            {!uploadSuccess ? (
              <FileUplaod />
            ) : uploadSuccess ? (
              <div className="mt-20">
                <DataTable loading={isSubmitting} translate={t} />
              </div>
            ) : processing ? (
              <ProcessFile transaction={[]} loading={false} translate={t} />
            ) : null}
          </Fragment>
        </Suspense>
      </Content>
    </div>
  );
};

export default withRouter(BulkPayment);
