import React, { useEffect } from "react";
import { Input, Row, Col, Button, Alert, Select } from "antd";
import { ArrowLeftCircle } from 'react-feather';
import "antd-country-phone-input/dist/index.css";
import { AppDispatch } from "../../../helpers/appDispatch";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { appSelector } from "../../../helpers/appSelector";
import { getCurrencies } from "../../../store/transactions";
import { FxRateRq } from "../../../interfaces/FxCurrency";
import { addFxRateRequest, clearSomeBooleans } from "../../../store/settings";
interface CreateFXProps {
  isSubmitting: boolean;
  Form: any;
  form: any;
  errors: any;
  success: boolean;
  values: FxRateRq;
  translate: any;
  onClickBackButton: () => void
}

const AddFxRate: React.FC<CreateFXProps> = ({
  Form,
  errors,
  form,
  isSubmitting,
  success,
  values,
  translate,
  onClickBackButton
}) => {
  const FormItem = Form.Item;
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const { currencies } = appSelector((state) => state.transaction);
  const { error, createFxSuccess, createFxFailure } = appSelector((state) => state.settings);
  const { Option } = Select;

  useEffect(() => {
    dispatch(getCurrencies());
    dispatch(clearSomeBooleans())

  }, [dispatch]);

  const handleSubmit = async (values: FxRateRq) => {
    const payload: FxRateRq = {
      SourceCurrency: values.SourceCurrency,
      DestinationCurrency: values.DestinationCurrency,
      Selling: values.Selling,
      Buying: values.Buying,
      Median: values.Median
    }

   await dispatch(addFxRateRequest(payload))
  }

  return (
    <Row justify="center" align="middle">
      <Col span={20} md={20} sm={24} xs={24} className="profile-box">
        <div className="upper-header" style={{color:'#0090fe', display:'flex'}} onClick={()=> onClickBackButton()}>
            <ArrowLeftCircle
              size={30}
              style={{ cursor: 'pointer' }}
            />
            {/* <span style={{fontSize:'18px', alignSelf:'center', marginLeft:'2px'}} onClick={()=> onClickBackButton()}>{translate("general.back")}</span> */}
        </div>
        <h3>{translate("general.createFx")}</h3>
        {createFxSuccess ?
          <Alert type="success" message="Success"/>
          : null}
        {error || createFxFailure ? (
              <Alert type="error" message={`${JSON.stringify(error)}`} />
            ) : null}
        <Row justify="center" align="middle">
          <Col span={8} className="form-container">
            <Form
              form={form}
              name="fx-form"
              onFinish={handleSubmit}
              initialValues={values}
              scrollToFirstError
            >
              {success ? (
                <div style={{ marginBottom: 20 }}>
                  <Alert message="FX created successfully" type="success" />
                </div>
              ) : null}
              {errors ? (
                <div style={{ marginBottom: 20 }}>
                  {/* <Alert message={JSON.stringify(errors)} type="error" /> */}
                </div>
              ) : null}
              <span>{translate("general.sourceCurrency")}</span>
              <Form.Item
                name="SourceCurrency"
                rules={[
                  {
                    required: true,
                    message: `${translate("general.currencyRequired")}`,
                  }
                ]}
              >
                <Select
                  placeholder={`${t("general.sourceCurrency")}`}
                  style={{ width: "100%" }}
                  // onChange={() => handleSourceCurrencyChangeEvent()}
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    option.value
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear
                  showSearch
                >
                  {currencies.length &&
                    currencies.map((p) => (
                      <Option value={p.code} key={p.currencyCodeId}>
                        {p.name} {"("+ p.code+")"}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <span>{translate("general.destinationCurrency")}</span>
              <Form.Item
                name="DestinationCurrency"
                rules={[{required: true, message: `${translate("general.currencyRequired")}`}]}
              >
                <Select
                  placeholder={`${t("general.destinationCurrency")}`}
                  style={{ width: "100%" }}
                  // onChange={() => handleDestinationCurrencyChangeEvent()}
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    option.value
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear
                  showSearch
                >
                  {currencies.length &&
                    currencies.map((p) => (
                      <Option value={p.code} key={p.currencyCodeId}>
                        {p.name} {"("+ p.code+")"}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <span>{translate("general.BuyingPrice")}</span>
              <FormItem
                hasFeedback
                name="Buying"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Input placeholder={translate("general.BuyingPrice")} />
              </FormItem>
              <span>{translate("general.sellingPrice")}</span>
              <FormItem
                hasFeedback
                name="Selling"
                rules={[
                  {
                    required: true,
                    message: "This field is required"
                  }
                ]}
              >
                <Input placeholder={translate("general.sellingPrice")} />
              </FormItem>
              <span>{translate("general.MedianPrice")}</span>
              <FormItem
                hasFeedback
                name="Median"
                rules={[{ required: true, message: "This field is required" }, {
                  
                }]}
              >
                <Input placeholder={translate("general.MedianPrice")} />
              </FormItem>

              <Button
                htmlType="submit"
                type="primary"
                size="large"
                loading={isSubmitting}
              >
                {translate("general.createFx")}
              </Button>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AddFxRate;
