import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { BulkPaymentTypes } from "./types";
import { callApiPost, callApiGet } from "../../utils/api";
import {
  bulkPaymentFailure,
  // bulkPaymentIncreaseStep,
  bulkPaymentSuccess,
  fileListSuccess,
  getFilesFailure,
  getFilesSuccess,
  getProviderSuccess,
  uploadFailure,
  uploadSuccess,
} from "./actions";
import { path } from "../../helpers/path";
import { logout } from "../auth";
import { BulkPayload } from "../../interfaces/BulkPayment";

function* getProviders(): any {
  try {
    const res = yield call(callApiGet, "payments/getmobilewalletproviders");
    const newres = res.data.map((item: any, name: any) => {
      name = "name";
      return { [name]: item };
    });
    yield put(getProviderSuccess(newres));
  } catch (err: any) {
    if (err && err.response) {
      if (err.response.status === 401) {
        yield put(logout());
        localStorage.removeItem("user");
        localStorage.removeItem("persist:root");
        localStorage.clear();
        window.location.href = path.login;
      } else {
        //yield put(getProvidersFailure('An error occured during payment.'))
      }
    } else {
      throw err;
    }
  }
}

function* uploadFile({ payload }: { type: string; payload: FormData }): any {
  try {
    const res = yield call(callApiPost, "payments/importfile", payload);
    yield put(uploadSuccess(res.data));

    // yield put(bulkPaymentIncreaseStep(1));
  } catch (err: any) {
    if (err && err.response) {
      if (err.response.status === 401) {
        yield put(logout());
        localStorage.removeItem("user");
        localStorage.removeItem("persist:root");
        localStorage.clear();
        window.location.href = path.login;
      } else {
        yield put(uploadFailure(err.response.data));
      }
    } else {
      yield put(
        uploadFailure("An error occured when making request to server")
      );
    }
  }
}

function* uploadedFilePreview({
  payload,
}: {
  type: string;
  payload: number;
}): any {
  try {
    const res = yield call(callApiGet, `payments/filepreview/${payload}`);
    yield put(fileListSuccess(res.data));
  } catch (err: any) {
    if (err && err.response) {
      if (err.response.status === 401) {
        yield put(logout());
        localStorage.removeItem("user");
        localStorage.removeItem("persist:root");
        localStorage.clear();
        window.location.href = path.login;
      } else {
        yield put(uploadFailure(err.response.data));
      }
    } else {
      yield put(uploadFailure("An unknwon error occurred"));
    }
  }
}

function* bulkPaymentRequest({
  payload,
}: {
  type: string;
  payload: BulkPayload;
}): any {
  try {
    const res = yield call(
      callApiPost,
      `payments/updatepaymentstatuses?fileId=${payload.fileId}&approvalStatus=${payload.approvalStatus}`,
      payload
    );
    // yield put(bulkPaymentIncreaseStep(1));
    yield put(bulkPaymentSuccess(res.data));
  } catch (err: any) {
    if (err && err.response) {
      if (err.response.status === 401) {
        yield put(logout());
        localStorage.removeItem("user");
        localStorage.removeItem("persist:root");
        localStorage.clear();
        window.location.href = path.login;
      } else {
        yield put(bulkPaymentFailure(err.response.data));
      }
    } else {
      yield put(
        bulkPaymentFailure("An error occured when making request to server")
      );
    }
  }
}

function* getPaymentFiles(): any {
  try {
    const res = yield call(callApiGet, "payments/getfiles");
    if (res.status === 200) {
      yield put(getFilesSuccess(res.data));
    }
  } catch (err: any) {
    if (err && err.response) {
      if (err && err.response === 401) {
        yield put(logout());
        localStorage.removeItem("user");
        localStorage.removeItem("persist:root");
        localStorage.clear();
        window.location.href = path.login;
      } else {
        yield put(getFilesFailure(err.response.data));
      }
    } else {
      yield put(getFilesFailure("An unknwon error occurred"));
    }
  }
}

function* watchUploadFile() {
  yield takeEvery(BulkPaymentTypes.UPLOAD_FILE, uploadFile);
}
function* watchUploadedFilePreview() {
  yield takeEvery(BulkPaymentTypes.FILE_PREVIEW_REQUEST, uploadedFilePreview);
}

function* watchFetchProviders() {
  yield takeEvery(BulkPaymentTypes.GET_PROVIDERS, getProviders);
}

function* watchBulkPaymentRequest() {
  yield takeEvery(
    BulkPaymentTypes.COMPLETE_PAYMENT_REQUEST,
    bulkPaymentRequest
  );
}

function* watchGetPaymentFiles() {
  yield takeEvery(
    BulkPaymentTypes.GET_BULKPAYMENT_FILES_REQUEST,
    getPaymentFiles
  );
}

function* bulkPaymentSaga(): Generator {
  yield all([
    fork(watchUploadFile),
    fork(watchUploadedFilePreview),
    fork(watchFetchProviders),
    fork(watchBulkPaymentRequest),
    fork(watchGetPaymentFiles),
  ]);
}

export { bulkPaymentSaga };
