import React, { Suspense, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Layout, Spin, Row, Form } from 'antd'
import { useDispatch } from 'react-redux'
import { appSelector } from '../helpers/appSelector'
import { AppDispatch } from '../helpers/appDispatch'
import { MerchantData } from '../interfaces'
import { allocateFundsRequest } from '../store/allocate-funds'
import { isEmpty } from '../helpers/isEmpty'
import { useTranslation } from 'react-i18next'
import { getMerchantsRequest } from '../store/reports'
import FundAllocation from '../components/allocateFunds'

const { Content } = Layout

const AllocateFunds = () => {
  const dispatch: AppDispatch = useDispatch()
  const { t } = useTranslation()
  const reports = appSelector((state) => state.reports)
  const allocateFundState = appSelector((state) => state.allocateFundState)
  const [merchants, setMerchants] = useState<MerchantData[]>(reports.merchants)
  const { isSubmitting, success, error} = allocateFundState
  const params = {
    currency: '',
    merchantId: 0,
    amount: 0
  }
  const [form] = Form.useForm()

  useEffect(() => {
    const { merchants } = reports
    if (isEmpty(merchants)) {
      dispatch(getMerchantsRequest())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const { merchants } = reports
    setMerchants(merchants)
  }, [reports])

  const onAllocateFunds = (values: any) => {
    const { merchant, amount, currency } = values
    params.merchantId = merchant
    params.amount = amount
    params.currency = currency
    dispatch(allocateFundsRequest(params))
  }

  return (
    <div className="padding-box">
      <Content className="site-layout-background site-box">
        <Suspense
          fallback={
            <Row className="suspense-container">
              <div style={{ marginTop: '200px' }}>
                <Spin />
              </div>
            </Row>
          }
        >
          <FundAllocation
            onSubmit={onAllocateFunds}
            merchants={merchants} 
            isSubmitting={isSubmitting}
            Form={Form}
            form={form}
            errors={error}
            success={success}
            translate={t}
          />
        </Suspense>
      </Content>
    </div>
  )
}

export default withRouter(AllocateFunds)
