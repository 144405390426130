import React from "react";
import { Row, Col, Button, Alert } from "antd";

import "antd-country-phone-input/dist/index.css";
import { appSelector } from "../../helpers/appSelector";
import { roles } from "../../helpers/constants";

interface CreateMerchantProps {
  onSubmit(): void;
  isSubmitting: boolean;

  errors: any;
  success: boolean;

  translate: any;
}

const Approve: React.FC<CreateMerchantProps> = ({
  errors,

  isSubmitting,
  onSubmit,
  success,

  translate,
}) => {
  const { user } = appSelector((state) => state.auth);
  let role: any;
  if (user) {
    role = user.roles.find((r) => r === roles.SuperMerchant);
  } else {
    role = roles.SuperMerchant;
  }

  return (
    <Row justify="center" align="middle">
      <Col span={20} md={20} sm={24} xs={24} className="profile-box">
        <h3>{translate("general.approve")}</h3>

        <Row justify="center" align="middle">
          <Col span={8} className="form-container">
            {success ? (
              <div style={{ marginBottom: 20 }}>
                <Alert
                  message="Payments Approved successfully"
                  type="success"
                />
              </div>
            ) : null}

            {JSON.stringify(errors) !== "{}" ? (
              <div style={{ marginBottom: 20 }}>
                <Alert message={JSON.stringify(errors)} type="error" />
              </div>
            ) : null}

            {role !== undefined && role === roles.SuperMerchant ? (
              <Button
                htmlType="submit"
                type="primary"
                size="large"
                loading={isSubmitting}
                onClick={onSubmit}
                disabled={success}
              >
                {translate("general.approve")}
              </Button>
            ) : null}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Approve;
