import React, { useState, useEffect, lazy, Suspense } from "react";
import { Layout, Row, Spin } from "antd";

import { useDispatch } from "react-redux";
import { appSelector } from "../helpers/appSelector";
import { AppDispatch } from "../helpers/appDispatch";
import { withRouter, useHistory } from "react-router-dom";

import { path } from "../helpers/path";
import { useTranslation } from "react-i18next";

import { clearStates, getFilesRequest } from "../store/bulk-payment";

const FilterMenu = lazy(
  () => import("../components/bulkpayment-pages/FilterMenu")
);
const Files = lazy(
  () => import("../components/bulkpayment-pages/PaymentFiles")
);

const PaymentPages = () => {
  const dispatch: AppDispatch = useDispatch();

  const { files, loadingFiles } = appSelector((state) => state.bulkPayment);
  const { Content } = Layout;

  const [fileData, setFileData] = useState(files);

  const { t } = useTranslation();
  const history = useHistory();
  const [processStatus, setProcessStatus] = useState([]);
  useEffect(() => {
    dispatch(clearStates());
    dispatch(getFilesRequest());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const data: string = JSON.stringify(files);
    localStorage.setItem("status", data);
  }, [files]);

  const onClickRow = (fileId: number) => {
    history.push(`${path.file}/${fileId}`);
  };

  const onReset = (form: any) => {
    form.resetFields();
    // setPageData(pages);
  };

  return (
    <div className="padding-box">
      <Content className="site-layout-background site-box">
        <Suspense
          fallback={
            <Row className="suspense-container">
              <div style={{ marginTop: "200px" }}>
                <Spin />
              </div>
            </Row>
          }
        >
          {/* <FilterMenu onReset={onReset} onSearch={onReset} translate={t} /> */}
          <div className="margin-top">
            <Row style={{ position: "relative" }}>
              <h4 className="transaction-chart-text">
                {t("general.paymentPage")}
              </h4>
              <div className="utility-buttons">
                {/* <Button
                  type="primary"
                  className="export-buttons"
                  onClick={() => reloadPages()}
                >
                  {t("general.newPageText")}
                </Button> */}
              </div>
            </Row>
          </div>

          <Files
            files={files}
            onClickRow={onClickRow}
            loading={loadingFiles}
            translate={t}
          />
        </Suspense>
      </Content>
    </div>
  );
};

export default withRouter(PaymentPages);
