import React from 'react'
import { Input, Row, Col, Button, Alert, Select } from 'antd'
import 'antd-country-phone-input/dist/index.css'
import { MerchantData } from '../../interfaces'
import { isEmpty } from '../../helpers/isEmpty';

interface Props {
  onSubmit(values: any) : void
  isSubmitting: boolean
  Form: any
  form: any
  errors: any
  success: boolean
  merchants: MerchantData[]
  translate: any
}

const FundAllocation: React.FC<Props> = ({
  Form,
  errors,
  form,
  isSubmitting,
  onSubmit,
  success,
  merchants,
  translate
}) => {
    const { Option } = Select;

  return (
    <Row justify="center" align="middle">
      <Col span={20} md={20} sm={24} xs={24} className="profile-box">
        <h3>{translate('general.Allocate_Funds')}</h3>

        <Row justify="center" align="middle">
          <Col span={8} className="form-container">
            <Form
              form={form}
              name="merchant-form"
              onFinish={(onSubmit)}
              scrollToFirstError
            >
              {success ? (
                <div style={{ marginBottom: 20 }}>
                  <Alert
                    message="Transaction Successful"
                    type="success"
                  />
                </div>
              ) : null}
              {errors ? (
                <div style={{ marginBottom: 20 }}>
                  <Alert message={JSON.stringify(errors)} type="error" />
                </div>
                ) : null}
                                      
                <Form.Item 
                    name="merchant"
                    hasFeedback
                    rules={[{ required: true, message: 'This field is required' }]}
                          >
                <Select
                    placeholder={`${translate('general.merchant')}: ${translate(
                        'general.default'
                    )}=${translate('general.all')}`}
                    style={{ width: '100%' }}
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                        option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    className="select-merchant"             
                >
                    {!isEmpty(merchants) &&
                        merchants.map((m) => (
                        <Option value={m.merchantId} key={m.merchantId}>
                            {m.name}
                        </Option>
                    ))}
                                  </Select>
            </Form.Item>
                <Form.Item name="currency" 
                    class="ant-row ant-form-item-row"
                    hasFeedback
                    rules={[{ required: true, message: 'This field is required' }]}
                >
                <Select
                    className='ant-select-selector-text'
                    placeholder={`${translate('general.currency')}`}
                >
                    <Option value="USD" key="USD">
                        USD
                    </Option>
                    <Option value="CDF" key="CDF">
                        CDF
                    </Option>
                </Select>
            </Form.Item>         
                      
            <Form.Item
                hasFeedback
                name="amount"
                rules={[{ required: true, message: 'This field is required' }]}
            >
                <Input placeholder={translate('general.amount')} />
            </Form.Item>

            <Button
                htmlType="submit"
                type="primary"
                size="large"
                loading={isSubmitting}
            >
                {translate('general.send')}
            </Button>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default FundAllocation
