import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Layout, Menu, Image, Button } from "antd";
import * as FeatherIcons from "react-feather";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import logo from "../../images/logo_symbol.png";
import logo2 from "../../images/logo_transparent_background.png";
import { changeMenu, changeMenuHeader } from "../../store/utils";
import { menu, menuHeadings } from "../../helpers/menu";
import { appSelector } from "../../helpers/appSelector";
import { AppDispatch } from "../../helpers/appDispatch";
import { path } from "../../helpers/path";
import { roles } from "../../helpers/constants";
import SubMenu from "antd/lib/menu/SubMenu";

interface SideNavProps {
  collapsed: boolean;
  onCollapsed(): void;
}

const { Sider } = Layout;

export const SideNav: React.FC<SideNavProps> = ({ collapsed, onCollapsed }) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const utils = appSelector((state) => state.utils);
  const [activeMenu, setActiveMenu] = useState(utils.activeMenu);
  const { user } = appSelector((state) => state.auth);
  let role;

  if (user) {
    role = user.roles.find((r) => r === roles.SuperMerchant);
  } else {
    role = roles.SuperMerchant;
  }

  useEffect(() => {
    const { activeMenu } = utils;
    setActiveMenu(activeMenu);
  }, [utils]);

  const switchMenu = (menu: string, header: string) => {
    dispatch(changeMenu(menu));
    dispatch(changeMenuHeader(header));
  };

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapsed}
      collapsedWidth="0"
      breakpoint="lg"
    >
      <div className="logo">
        {collapsed ? (
          <Image src={logo2} alt="logo" preview={false} />
        ) : (
          <Image src={logo} alt="logo" preview={false} />
        )}
      </div>
      <Menu theme="light" mode="inline" defaultSelectedKeys={[activeMenu]}>
        <Menu.ItemGroup key="g1" title={t("general.dashboard").toUpperCase()}>
          <Menu.Item
            key={menu.DASHBOARD}
            icon={
              <FeatherIcons.Home
                className="ant-menu-item-icon anticon"
                size={14}
              />
            }
            onClick={() => switchMenu(menu.DASHBOARD, menuHeadings.DASHBOARD)}
          >
            <NavLink to={path.dashboard}>{t(`general.dashboard`)}</NavLink>
          </Menu.Item>
        </Menu.ItemGroup>
        <Menu.ItemGroup key="g2" title={t("general.payments").toUpperCase()}>
          <Menu.Item
            key={menu.TRANSACTIONS}
            icon={
              <FeatherIcons.ShoppingBag
                className="ant-menu-item-icon anticon"
                size={14}
              />
            }
            onClick={() => switchMenu(menu.TRANSACTIONS, menuHeadings.PAYMENTS)}
          >
            <NavLink to={path.tranasctions}>
              {t("general.transactions")}
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key={menu.VASTRANSACTIONS}
            icon={
              <FeatherIcons.ShoppingBag
                className="ant-menu-item-icon anticon"
                size={14}
              />
            }
            onClick={() =>
              switchMenu(menu.VASTRANSACTIONS, menuHeadings.REPORTS)
            }
          >
            <NavLink to={path.vasTransactions}>
              {t("general.VAS Transactions")}
            </NavLink>
          </Menu.Item>
          {/* <Menu.Item
            key={menu.B2CTRANSACTIONS}
            icon={
              <FeatherIcons.ShoppingBag
                className="ant-menu-item-icon anticon"
                size={14}
              />
            }
            onClick={() =>
              switchMenu(menu.B2CTRANSACTIONS, menuHeadings.REPORTS)
            }
          >
            <NavLink to={path.b2ctransactions}>
              {t("general.B2C Transactions")}
            </NavLink>
          </Menu.Item> */}

         {/* <SubMenu
            key={menu.BULK_PAYMENTS}
            title={t("general.bulkPayment")}
            icon={
              <FeatherIcons.Truck
                className="ant-menu-item-icon anticon"
                size={14}
              />
            }
          >
            <Menu.ItemGroup>
              { <Menu.Item
                key={menu.BULK_PAYMENT}
                icon={
                  <FeatherIcons.Truck
                    className="ant-menu-item-icon anticon"
                    size={14}
                  />
                }
                onClick={() =>
                  switchMenu(menu.BULK_PAYMENT, menuHeadings.PAYMENTS)
                }
              >
                <NavLink to={path.bulkPayment}>
                  {t("general.newBulkPayment")}
                </NavLink>
              </Menu.Item> }

               <Menu.Item
                key={menu.PENDINGPAYMENTS}
                icon={
                  <FeatherIcons.ShoppingBag
                    className="ant-menu-item-icon anticon"
                    size={14}
                  />
                }
                onClick={() =>
                  switchMenu(menu.PENDINGPAYMENTS, menuHeadings.PAYMENTS)
                }
              >
                <NavLink to={path.pendingPayments}>
                  {t("general.pendingBulkPayment")}
                </NavLink>
              </Menu.Item>
            </Menu.ItemGroup> 
          </SubMenu>*/}
          {/* {role !== undefined && role === roles.SuperMerchant ? (
            <Menu.Item
              key={menu.Allocate_Funds}
              icon={
                <FeatherIcons.DollarSign
                  className="ant-menu-item-icon anticon"
                  size={14}
                />
              }
              onClick={() =>
                switchMenu(
                  menu.Allocate_Funds,
                  menuHeadings.Allocate_Funds
                )
              }
            >
              <NavLink to={path.allocateFunds}>
                {t("general.Allocate_Funds")}
              </NavLink>
            </Menu.Item>
          ): null} */}
        </Menu.ItemGroup>
        {/* <SubMenu
              key={menu.BILLERS}
              title={t("general.billers")}
              icon={
                <FeatherIcons.Briefcase
                  className="ant-menu-item-icon anticon"
                  size={14}
                />
              }
            >
              <Menu.ItemGroup>
                <Menu.Item
                  key={menu.SOCODEE}
                  icon={
                    <FeatherIcons.BatteryCharging
                      className="ant-menu-item-icon anticon"
                      size={14}
                    />
                  }
                  onClick={() => switchMenu(menu.SOCODEE, menuHeadings.PAYMENTS)}
                >
                  <NavLink to={path.socodee}>{t("general.socodee")}</NavLink>
                </Menu.Item>

                <Menu.Item
                  key={menu.SNEL}
                  icon={
                    <FeatherIcons.Database
                      className="ant-menu-item-icon anticon"
                      size={14}
                    />
                  }
                  onClick={() => switchMenu(menu.SNEL, menuHeadings.PAYMENTS)}
                >
                  <NavLink to={path.snel}>{t("general.snel")}</NavLink>
                </Menu.Item>
              </Menu.ItemGroup>
            </SubMenu> */}
        <Menu.ItemGroup key="g3" title={t("general.commerce").toUpperCase()}>
          <Menu.Item
            key={menu.PAYMENT_PAGES}
            icon={
              <FeatherIcons.Layers
                className="ant-menu-item-icon anticon"
                size={14}
              />
            }
            onClick={() =>
              switchMenu(menu.PAYMENT_PAGES, menuHeadings.COMMERCE)
            }
          >
            <NavLink to={path.paymentPages}>
              {t("general.paymentPages")}
            </NavLink>
          </Menu.Item>
        </Menu.ItemGroup>
        {/* <Menu.ItemGroup key="g4" title={t('general.payouts').toUpperCase()}>
          <Menu.Item
            key={menu.PAYOUTS}
            icon={
              <FeatherIcons.ShoppingBag
                className="ant-menu-item-icon anticon"
                size={14}
              />
            }
            onClick={() => switchMenu(menu.PAYOUTS, menuHeadings.PAYOUTS)}
          >
            <NavLink to={path.payouts}>{t('general.payouts')}</NavLink>
          </Menu.Item>
        </Menu.ItemGroup> */}
        {role !== undefined && role === roles.SuperMerchant ? (
          <Menu.ItemGroup key="g5" title={t("general.reports").toUpperCase()}>
            <Menu.Item
              key={menu.MERCHANT_OVERVIEW}
              icon={
                <FeatherIcons.Users
                  className="ant-menu-item-icon anticon"
                  size={14}
                />
              }
              onClick={() =>
                switchMenu(menu.MERCHANT_OVERVIEW, menuHeadings.REPORTS)
              }
            >
              <NavLink to={path.merchantsOverview}>
                {t("general.merchantOverview")}
              </NavLink>
            </Menu.Item>

            <Menu.Item
              key={menu.MERCHANT_CHANNELS}
              icon={
                <FeatherIcons.User
                  className="ant-menu-item-icon anticon"
                  size={14}
                />
              }
              onClick={() =>
                switchMenu(menu.MERCHANT_CHANNELS, menuHeadings.REPORTS)
              }
            >
              <NavLink to={path.merchantsChannels}>
                {t("general.Merchants Channels")}
              </NavLink>
            </Menu.Item>

            <Menu.Item
              key={menu.VAS_PROCESSED}
              icon={
                <FeatherIcons.Cpu
                  className="ant-menu-item-icon anticon"
                  size={14}
                />
              }
              onClick={() =>
                switchMenu(menu.VAS_PROCESSED, menuHeadings.REPORTS)
              }
            >
              <NavLink to={path.vasProcessed}>
                {t("general.VASProcessed")}
              </NavLink>
            </Menu.Item>

            <Menu.Item
              key={menu.FEE_REPORTS}
              icon={
                <FeatherIcons.BarChart2
                  className="ant-menu-item-icon anticon"
                  size={14}
                />
              }
              onClick={() => switchMenu(menu.FEE_REPORTS, menuHeadings.REPORTS)}
            >
              <NavLink to={path.feeReports}> {t("general.FEEReports")}</NavLink>
            </Menu.Item>

            <Menu.Item
              key={menu.PENDING_TRANSACTIONS}
              icon={
                <FeatherIcons.AlertTriangle
                  className="ant-menu-item-icon anticon"
                  size={14}
                />
              }
              onClick={() =>
                switchMenu(
                  menu.PENDING_TRANSACTIONS,
                  menuHeadings.PENDINGTRANSACTIONS
                )
              }
            >
              <NavLink to={path.pendingTransactions}>
                {t("general.PendingTransactions")}
              </NavLink>
            </Menu.Item>

            {/* <Menu.Item
              key={menu.BILLERS}
              icon={
                <FeatherIcons.Briefcase
                  className="ant-menu-item-icon anticon"
                  size={14}
                />
              }
              onClick={() => switchMenu(menu.BILLERS, menuHeadings.BILLERS)}
            >
              <NavLink to={path.pendingTransactions}>
                {t("general.billers")}
              </NavLink>
            </Menu.Item> */}
          </Menu.ItemGroup>
        ) : null}
        <Menu.ItemGroup
          key="g6"
          title={t("general.configurations").toUpperCase()}
        >
          <Menu.Item
            key={menu.SETTINGS}
            icon={
              <FeatherIcons.Settings
                className="ant-menu-item-icon anticon"
                size={14}
              />
            }
            onClick={() =>
              switchMenu(menu.SETTINGS, menuHeadings.CONFIGURATION)
            }
          >
            <NavLink to={path.settings}>{t("general.settings")}</NavLink>
          </Menu.Item>
        </Menu.ItemGroup>
      </Menu>
      {!collapsed ? (
        <div className="menu-info">
          <p>{t("general.menuInfo")}</p>
          <a href="mailto:info@arakapay.com?subject=Araka API Assistance">
            <Button type="primary" className="menu-info-btn">
              {t("general.apiAssistance")}
            </Button>
          </a>
        </div>
      ) : null}
    </Sider>
  );
};
