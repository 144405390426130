export interface FileUploadResponse {
  pageTitle: string;
  pageLogo: string;
  pageDescription: string;
  customerFullName: string;
  customerPhoneNumber: string;
  customerEmailAddress: string;
  transactionReference: string;
  currency: string;
  amount: string;
  redirectURL: string;
  processId: string;
  paymentPageId: number;
  momoProvider: string;
  momoAccountNumber: string;
  paymentInfo: any;
}

export interface FilePreview {
  id: number;
  fileId: number;
  amount: number;
  currency: string;
  msisdn: string;
  statusId: number;
  paymentDate: string;
}

export interface Providers {
  name: string;
}

export interface Transaction {
  amount: string;
  currency: string;
  msisdn: number;
  providerName: string;
}

export interface BulkPay {
  fileId: string;
  order: {
    transactionReference: string;
    currency: string;
    amount: string;
    customerPhoneNumber: string;
  };
  destination: {
    provider: string;
    walletID: string;
  };
}

export interface BulkPaymentChecker {
  bulkpaymentFile?: FilePreview[];
  bulkPayload?: BulkPaymentFile[];
  approvalStatus: number;
}

export interface PaymentFile {
  fileId: number;
  fileName: string;
  uploadDate: string;
  userName: string;
  statusId: number;
}

export enum PaymentStatus {
  "PENDINGAPPROVAL" = 2,
  "APPROVED" = 1,
  "FAILED" = 3,
}

export enum ProcessStatus {
  "PROCESSED" = 1,
  "NOTPROCESSED" = 2,
}

export interface BulkPaymentFile {
  id: number;
  fileId: number;
  amount: number;
  msisdn: string;
  currency: string;
  transactionReference: string;
  provider: string;
  statusId: number;
}

export interface BulkPayload {
  fileId: number;
  approvalStatus: number;
}
