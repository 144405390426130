import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { all, fork } from "redux-saga/effects";
import { connectRouter, RouterState } from "connected-react-router";
import { History } from "history";
import storage from "redux-persist/lib/storage";
import { authSaga } from "./auth/sagas";
import { transactionSaga } from "./transactions/sagas";
import { b2ctransactionTableSaga } from "./b2cTransactionTable/sagas";
import { homeSaga } from "./home/sagas";
import { paymentPagesSaga } from "./payment-pages/sagas";
import { bulkPaymentSaga } from "./bulk-payment/sagas";
import { settingsSaga } from "./settings/sagas";
import { overviewSaga } from "./merchant-overview/sagas";
import { vasSaga } from "./vas-processed/sagas";
import { reportsSaga } from "./reports/sagas";
import { channelSaga } from "./merchant-channels/sagas";
import { b2cPaymentPageSaga } from "./b2ctransactions/sagas";
import { AuthState, authReducer } from "./auth";
import { HomeState, homeReducer } from "./home";
import { TransactionState, transactionReducer } from "./transactions";
import {
  B2CTransactionTableState,
  b2cTransactionTableReducer,
} from "./b2cTransactionTable";
import { PaymentPagesState, paymentPagesReducer } from "./payment-pages";
import { UtilsState, utilsReducer } from "./utils";
import { SettingsState, settingsReducer } from "./settings";
import { MerchantsOverviewState, overviewReducer } from "./merchant-overview";
import { VASProcessedState, vasReducer } from "./vas-processed";
import { ReportsState, reportsReducer } from "./reports";
import { MerchantsChannelsState, channelsReducer } from "./merchant-channels";
import { B2CPaymentReducer, B2CTransactionState } from "./b2ctransactions";
import { bulkPaymentReducer, BulkPaymentState } from "./bulk-payment";
import { VASTransactionState } from "./vas-transactions/types";
import { vasTransactionTableReducer } from "./vas-transactions";
import { vastransactionTableSaga } from "./vas-transactions/sagas";
import { socodeeTransactionTableReducer } from "./socodee/reducer";
import { SocodeeTransactionState } from "./socodee/types";
import { socodeetransactionTableSaga } from "./socodee/sagas";
import { sneltransactionTableSaga } from "./snel/sagas";
import { SnelTransactionState, snelTransactionTableReducer } from "./snel";
import { AllocateFundState, allocateFundsReducer } from "./allocate-funds";
import { allocateFundsSaga } from "./allocate-funds/sagas";

export type ApplicationState = {
  auth: AuthState;
  home: HomeState;
  transaction: TransactionState;
  b2cTransactionTable: B2CTransactionTableState;
  page: PaymentPagesState;
  utils: UtilsState;
  settings: SettingsState;
  overviews: MerchantsOverviewState;
  vas: VASProcessedState;
  reports: ReportsState;
  router: RouterState;
  channels: MerchantsChannelsState;
  b2cPayment: B2CTransactionState;
  payment: PaymentPagesState;
  bulkPayment: BulkPaymentState;
  vasTransaction: VASTransactionState;
  socodee: SocodeeTransactionState;
  snel: SnelTransactionState;
  allocateFundState: AllocateFundState;
};

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "auth",
    "home",
    "transaction",
    "b2cTransactionTable",
    "page",
    "utils",
    "settings",
    "overviews",
    "vas",
    "reports",
    "router",
    "channels",
    "b2cPayment",
    "bulkPayment",
    "vasTransaction",
    "socodee",
    "snel",
    "allocateFunds"
  ],
};

export const persistingReducer = (history: History) =>
  persistReducer(
    persistConfig,
    combineReducers({
      auth: authReducer,
      home: homeReducer,
      transaction: transactionReducer,
      b2cTransactionTable: b2cTransactionTableReducer,
      page: paymentPagesReducer,
      utils: utilsReducer,
      settings: settingsReducer,
      overviews: overviewReducer,
      vas: vasReducer,
      reports: reportsReducer,
      channels: channelsReducer,
      b2cPayment: B2CPaymentReducer,
      router: connectRouter(history),
      bulkPayment: bulkPaymentReducer,
      vasTransaction: vasTransactionTableReducer,
      socodee: socodeeTransactionTableReducer,
      snel: snelTransactionTableReducer,
      allocateFundState: allocateFundsReducer
    })
  );

export function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(transactionSaga),
    fork(homeSaga),
    fork(paymentPagesSaga),
    fork(bulkPaymentSaga),
    fork(settingsSaga),
    fork(overviewSaga),
    fork(vasSaga),
    fork(reportsSaga),
    fork(channelSaga),
    fork(b2cPaymentPageSaga),
    fork(b2ctransactionTableSaga),
    fork(vastransactionTableSaga),
    fork(socodeetransactionTableSaga),
    fork(sneltransactionTableSaga),
    fork(allocateFundsSaga),
  ]);
}
