import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { AllocateFundActionTypes } from './types'
import { callApiPost } from '../../utils/api'
import {  allocateFundSuccess,  allocateFundFailure } from './actions'
import { path } from '../../helpers/path'
import { logout } from '../auth'

function* allocateFunds({ payload }: { type: string; payload: any }): any {
  try {
    const res = yield call(callApiPost, 'payments/allocatefundstomerchant', payload)
    yield put(allocateFundSuccess(res.data))
  } catch (err: any) {
    if (err && err.response) {
      console.log(err)
      if (err.response.status === 401) {
        yield put(logout())
        localStorage.removeItem('user')
        localStorage.removeItem('persist:root')
        localStorage.clear()
        window.location.href = path.login
      } else if (err.response.status === 500) {
        yield put(allocateFundFailure('An unknwon error occurred'))
      } else{
        yield put(allocateFundFailure(err.response.data))
      }
    } else {
      yield put(allocateFundFailure('An unknwon error occurred'))
    }
  }
}

function* watchAllocateFunds() {
  yield takeEvery(AllocateFundActionTypes.ALLOCATE_FUNDS_REQUEST, allocateFunds)
}

function* allocateFundsSaga(): Generator {
  yield all([
    fork(watchAllocateFunds)
  ])
}

export { allocateFundsSaga }
