import React, { useEffect } from "react";
import { Tabs, Spin } from "antd";
import { EmptyTransactions } from "./EmptyTransactions";

import { isEmpty } from "../../helpers/isEmpty";
import { Transactions } from "./Transactions";
import {
  BulkPayload,
  BulkPaymentChecker,
  BulkPaymentFile,
  FilePreview,
  PaymentFile,
} from "../../interfaces/BulkPayment";

import { useTranslation } from "react-i18next";
import Approve from "./Approve";
import { useDispatch } from "react-redux";
import {
  bulkPaymentRequest,
  clearStates,
  getFilesRequest,
} from "../../store/bulk-payment";
import { AppDispatch } from "../../helpers/appDispatch";
import { appSelector } from "../../helpers/appSelector";

interface PageTransactionsProps {
  loading: boolean;
  transactions: FilePreview[];
  isSubmiting: boolean;
  success: boolean;
  errors: any;
  fileId: number;
}

export const PageTransactions: React.FC<PageTransactionsProps> = ({
  loading,
  transactions,
  isSubmiting,
  success,
  errors,
  fileId,
}) => {
  const dispatch: AppDispatch = useDispatch();

  const { TabPane } = Tabs;
  const { t } = useTranslation();
  let render: React.ReactNode;

  if (loading && isEmpty(transactions)) {
    render = (
      <div className="spinner">
        <Spin size="large" />
      </div>
    );
  }
  const process: string | null = localStorage.getItem("status");

  let processData: PaymentFile[] = [];

  if (process) {
    processData = JSON.parse(process);
  }

  if (!loading && isEmpty(transactions)) {
    render = <EmptyTransactions />;
  }

  if (!loading && !isEmpty(transactions)) {
    render = <Transactions transactions={transactions} />;
  }

  let data: BulkPaymentFile[] = [];
  for (let page of transactions) {
    data.push({
      id: page ? page.id : 0,
      fileId: page ? page.fileId : 0,
      amount: page ? page.amount : 0,
      msisdn: page ? page.msisdn : "",
      currency: page ? page.currency : "",
      transactionReference: "",
      provider: "MPESA",
      statusId: page ? page.statusId : 0,
    });
  }

  const approvePayments = () => {
    const payload: BulkPayload = {
      approvalStatus: 1,
      fileId: fileId,
    };
    dispatch(bulkPaymentRequest(payload));
  };

  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab={t("general.entries")} key="1">
        {render}
      </TabPane>

      {processData.find((f) => f.fileId === fileId)?.statusId === 2 && (
        <TabPane tab={<span>{t("general.approve")}</span>} key="2">
          <Approve
            isSubmitting={isSubmiting}
            errors={errors}
            success={success}
            translate={t}
            onSubmit={approvePayments}
          />
        </TabPane>
      )}
    </Tabs>
  );
};
