import React, { useEffect } from "react";
import { Table, Row, Col, Form, Button, message } from "antd";
import { AppDispatch } from "../../helpers/appDispatch";
import { useDispatch } from "react-redux";
import {
  bulkPaymentRequest,
  clearStates,
  uploadedFilePreview,
} from "../../store/bulk-payment";
import { appSelector } from "../../helpers/appSelector";
import {
  BulkPayload,
  BulkPaymentChecker,
  FilePreview,
} from "../../interfaces/BulkPayment";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

interface Props {
  // preview: FilePreview[];
  loading: boolean;
  translate: any;
}
interface TableData {
  fileId: number;
  amount: number;
  msisdn: string;
  currency: string;
}

const DataTable: React.FC<Props> = ({
  //onClickRow,
  loading,
  translate,
}) => {
  const columns: any[] = [
    {
      title: <span style={{ fontSize: "2rem", color: "#868686" }}>&bull;</span>,
      dataIndex: "status",
      key: "status",
      align: "center",
      className: "column-text",
      render: () => {
        return (
          <span style={{ fontSize: "2rem", color: "#41b883" }}>&bull;</span>
        );
      },
    },
    {
      title: `${translate("general.msisdn")}`,
      dataIndex: "msisdn",
      key: "msisdn",
      align: "center",
      render: (pageName: string, page: TableData) => (
        <span
          style={{ color: "#35b9e6" }}
          //onClick={() => onClickRow(page.msisdn)}
        >
          {pageName}
        </span>
      ),
    },
    {
      title: `${translate("general.currency")}`,
      dataIndex: "currency",
      key: "currency",
      align: "center",
    },
    {
      title: `${translate("general.amount")}`,
      dataIndex: "amount",
      key: "amount",
      align: "center",
    },
  ];
  const dispatch: AppDispatch = useDispatch();
  const { fileId, pages, filePreviewSuccess, success, error, uploadSuccess } =
    appSelector((state) => state.bulkPayment);

  let data: TableData[] = [];
  for (let page of pages) {
    data.push({
      fileId: page ? page.fileId : 0,
      amount: page ? page.amount : 0,
      msisdn: page ? page.msisdn : "",
      currency: page ? page.currency : "",
    });
  }
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(uploadedFilePreview(fileId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileId]);

  const submitPaymentToChecker = () => {
    // const payload: BulkPaymentChecker = {
    //   bulkpaymentFile: pages,
    //   approvalStatus: 2,
    // };
    const payload: BulkPayload = {
      fileId: fileId,
      approvalStatus: 2,
    };

    dispatch(bulkPaymentRequest(payload));
  };

  useEffect(() => {
    if (success || !uploadSuccess) {
      dispatch(clearStates());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, uploadSuccess]);
  return (
    <div>
      <Row gutter={20}>
        <Col span={24}>
          <div className="table-padding">
            <Table
              dataSource={data}
              columns={columns}
              bordered
              pagination={{
                hideOnSinglePage: true,
                total: data.length,
                showTotal: (total, range) => {
                  const tran = translate(`general.pagination`);
                  let t = tran.replace(`%d`, `${range[0]} - ${range[1]}`);
                  let s = t.replace(`%s`, total);
                  return s;
                },
              }}
              loading={!filePreviewSuccess}
            />
          </div>
        </Col>
      </Row>
      <Form.Item className="btn-input">
        {JSON.stringify(error) !== "{}"
          ? message.error(`${JSON.stringify(error)}`)
          : null}
        {success
          ? message.success("Payment Successfully Submitted for Approval")
          : null}
        <Button
          type="primary"
          className="login-btn"
          htmlType="submit"
          loading={loading}
          onClick={() => submitPaymentToChecker()}
          icon={loading ? <LoadingOutlined /> : null}
        >
          {!loading ? t("general.processPayment") : t("Please wait . .")}
        </Button>
      </Form.Item>
    </div>
  );
};

export default DataTable;
