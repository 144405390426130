import { action } from "typesafe-actions";
import { BulkPaymentTypes } from "./types";
import {
  BulkPay,
  BulkPayload,
  BulkPaymentChecker,
  FilePreview,
  PaymentFile,
} from "../../interfaces/BulkPayment";

export const uploadFile = (payload: FormData) =>
  action(BulkPaymentTypes.UPLOAD_FILE, payload);

export const uploadFailure = (error: any) =>
  action(BulkPaymentTypes.UPLOAD_FILE_FAILURE, error);

export const uploadSuccess = (response: any) =>
  action(BulkPaymentTypes.UPLOAD_FILE_SUCCESS, response);

export const uploadFailed = (error: any) =>
  action(BulkPaymentTypes.UPLOAD_FILE_FAILED, error);

export const getProviders = () => action(BulkPaymentTypes.GET_PROVIDERS);

export const getProviderSuccess = (response: any) =>
  action(BulkPaymentTypes.GET_PROVIDERS_SUCCESS, response);

export const uploadedFilePreview = (fileId: number) =>
  action(BulkPaymentTypes.FILE_PREVIEW_REQUEST, fileId);

export const fileListSuccess = (response: FilePreview[]) =>
  action(BulkPaymentTypes.FILE_PREVIEW, response);

export const bulkPaymentIncreaseStep = (step: number) =>
  action(BulkPaymentTypes.INCREASE_BULK_PAYMENT_STEP, step);

export const bulkPaymentDecreaseStep = (step: number) =>
  action(BulkPaymentTypes.DECREASE_BULK_PAYMENT_STEP, step);

export const clearStates = () => action(BulkPaymentTypes.CLEAR_STATES);

export const bulkPaymentRequest = (payload: BulkPayload) =>
  action(BulkPaymentTypes.COMPLETE_PAYMENT_REQUEST, payload);

export const bulkPaymentSuccess = (response: BulkPay) =>
  action(BulkPaymentTypes.COMPLETE_PAYMENT_SUCCESS, response);

export const bulkPaymentFailure = (error: any) =>
  action(BulkPaymentTypes.COMPLETE_PAYMENT_FAILURE, error);

export const getFilesRequest = () =>
  action(BulkPaymentTypes.GET_BULKPAYMENT_FILES_REQUEST);

export const getFilesSuccess = (response: PaymentFile[]) =>
  action(BulkPaymentTypes.GET_BULKPAYMENT_FILES_SUCESS, response);

export const getFilesFailure = (error: any) =>
  action(BulkPaymentTypes.GET_BULKPAYMENT_FILES_FAILURE, error);
