export enum AllocateFundActionTypes {
  ALLOCATE_FUNDS_REQUEST = '@@allocate-funds/ALLOCATE_FUNDS_REQUEST',
  ALLOCATE_FUNDS_SUCCESS = '@@allocate-funds/ALLOCATE_FUNDS_SUCCESS',
  ALLOCATE_FUNDS_FAILURE = '@@allocate-funds/ALLOCATE_FUNDS_FAILURE',
}

export type AllocateFundState = {
  readonly isSubmitting: boolean
  readonly success: boolean
  readonly failure: boolean
  readonly error: any
}
