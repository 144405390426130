export const menu = {
  DASHBOARD: "dashboard",
  DASHBOARD_YEARLY: "Dashboard Yearly",
  DASHBOARD_DAILY: "Dashboard Daily",
  DASHBOARD_WEEKLY: "Dashboard Weekly",
  DASHBOARD_MONTHLY: "Dashboard Monthly",
  TRANSACTIONS: "transactionsOverview",
  BULK_PAYMENT: "bulkPayment",
  BULK_PAYMENTS: "bulkPayments",
  PAYMENT_PAGES: "paymentPage",
  MERCHANT_PAYOUTS: "merchantPayouts",
  VAS_PROCESSED: "VASProcessed",
  FEE_REPORTS: "FEEReports",
  PENDING_TRANSACTIONS: "Pending Transactions",
  SETTINGS: "settings",
  MY_PAYOUTS: "My Payouts",
  MERCHANT_OVERVIEW: "merchantOverview",
  PROXYPAY_REPORTS: "ProxyPay Reports",
  PROXYPAY_SUBSCRIBERS: " Subscribers",
  PROXYPAY_TRANSACTIONS: "Transactions",
  PROXYPAY_VOLUMES: "Volumes",
  PROXYPAY_OVERVIEW: "Overview",
  PAYOUTS: "payouts",
  MERCHANT_CHANNELS: "Merchants Channels",
  B2CTRANSACTIONS: "B2C Transactions",
  VASTRANSACTIONS: "VAS Transactions",
  PENDINGPAYMENTS: "pendingBulkPayment",
  BILLERS: "billers",
  SOCODEE: "socodee",
  SNEL: "snel",
  Allocate_Funds: "Allocate_Funds",
  Accounts: "account",
};

export const menuHeadings = {
  DASHBOARD: "dashboard",
  PAYMENTS: "payments",
  COMMERCE: "commerce",
  REPORTS: "reports",
  CONFIGURATION: "configurations",
  PAYOUTS: "payouts",
  PENDINGTRANSACTIONS: "Pending Transactions",
  B2CTRANSACTIONS: "B2C Transactions",
  PENDINGPAYMENTS: "Pending Payments",
  Allocate_Funds: "Allocate_Funds",
  Accounts: "account"
};
