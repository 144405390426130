import { Transaction, Currency, DataStream } from "../../interfaces";

export enum TransactionTypes {
  GET_TRANSACTIONS_REQUEST = "@@socodeetransactions/GET_TRANSACTIONS_REQUEST",
  GET_TRANSACTIONS_SUCCESS = "@@socodeetransactions/GET_TRANSACTIONS_SUCCESS",
  GET_TRANSACTIONS_FAILURE = "@@socodeetransactions/GET_TRANSACTIONS_FAILURE",
  GET_CURRENCIES = "@@transaction/GET_CURRENCIES",
  GET_CURRENCIES_SUCCESS = "@@transaction/GET_CURRENCIES_SUCCESS",
  GET_CURRENCIES_FAILURE = "@@transaction/GET_CURRENCIES_FAILURE",
  EXPORT_TRANSACTIONS_REQUEST = "@@socodeetransactions/EXPORT_TRANSACTIONS_REQUEST",
  EXPORT_TRANSACTIONS_SUCCESS = "@@socodeetransactions/EXPORT_TRANSACTIONS_SUCCESS",
  EXPORT_TRANSACTIONS_FAILURE = "@@socodeetransactions/EXPORT_TRANSACTIONS_FAILURE",
  DOWNLOAD_RECEIPT_REQUEST = "@@transaction/DOWNLOAD_RECEIPT_REQUEST",
  DOWNLOAD_RECEIPT_SUCCESS = "@@transaction/DOWNLOAD_RECEIPT_SUCCESS",
  DOWNLOAD_RECEIPT_FAILURE = "@@transaction/DOWNLOAD_RECEIPT_FAILURE",
  CLEAR_VAS = "@@vas/CLEAR_VAS",
}

export type SocodeeTransactionState = {
  readonly transactions: Transaction | null;
  readonly loading: boolean;
  readonly error: any;
  readonly currencies: Currency[];
  readonly currenciesLoading: boolean;
  readonly currencyError: any;
  readonly isExporting: boolean;
  readonly isExportSuccess: boolean;
  readonly isExportError: boolean;
  readonly exportStream: DataStream | undefined;
  readonly exportError: any;
  readonly isRequestingDownload: boolean;
  readonly downloadRecieptSuccess: boolean;
  readonly downloadRecieptError: boolean;
  readonly downloadReceiptStream: DataStream | undefined;
  readonly downloadError: any;
};
