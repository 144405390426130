export const path = {
  home: "/",
  dashboard: "/dashboard",
  tranasctions: "/transactions",
  bulkPayment: "/bulkPayment",
  paymentPages: "/payment-pages",
  file: "/file",
  page: "/page",
  login: "/auth/login",
  pay: "/pay",
  example: "/example-pay",
  payment: "/payment",
  myPayouts: "/my-payouts",
  merchantsOverview: "/merchants-overview",
  vasProcessed: "/vas-processed",
  merchantPayouts: "/merchants-payouts",
  settings: "/settings",
  feeReports: "/fee-reports",
  proxyPaySubscribers: "/proxypay-subscribers",
  proxyPayTransactions: "/proxypay-transactions",
  proxyPayVolumes: "/proxypay-volumes",
  proxyPayOverview: "/proxypay-overview",
  payouts: "/payouts",
  pendingTransactions: "/pending-transactions",
  merchantsChannels: "/merchants-channels",
  forgottenPasssword: "/auth/forgotten-password",
  resetPassword: "/auth/reset-password",
  b2ctransactions: "/b2ctransactions",
  vasTransactions: "/vas-transactions",
  pendingPayments: "/bulkpayment-pages",
  socodee: "/socodee",
  snel: "/snel",
  allocateFunds: "/allocate-funds",
  accounts: "/accounts",
};
